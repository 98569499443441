/*FONT-FACCE*/
@font-face {
  font-family: 'Trenda';
  font-weight: 400;
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_A_0.eot');
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_A_0.eot?#iefix') format('embedded-opentype'), url('https://webassets.lovoo.com/fonts/trenda/35413A_A_0.woff2') format('woff2'), url('https://webassets.lovoo.com/fonts/trenda/35413A_A_0.woff') format('woff'), url('https://webassets.lovoo.com/fonts/trenda/35413A_A_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Trenda';
  font-weight: 500;
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_C_0.eot');
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_C_0.eot?#iefix') format('embedded-opentype'), url('https://webassets.lovoo.com/fonts/trenda/35413A_C_0.woff2') format('woff2'), url('https://webassets.lovoo.com/fonts/trenda/35413A_C_0.woff') format('woff'), url('https://webassets.lovoo.com/fonts/trenda/35413A_C_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Trenda';
  font-weight: 600;
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_3_0.woff2') format('woff2'), url('https://webassets.lovoo.com/fonts/trenda/35413A_3_0.woff') format('woff'), url('https://webassets.lovoo.com/fonts/trenda/35413A_3_0.eot'), url('https://webassets.lovoo.com/fonts/trenda/35413A_3_0.eot?#iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'Trenda';
  font-weight: 700;
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_4_0.eot');
  src: url('https://webassets.lovoo.com/fonts/trenda/35413A_4_0.eot?#iefix') format('embedded-opentype'), url('https://webassets.lovoo.com/fonts/trenda/35413A_4_0.woff2') format('woff2'), url('https://webassets.lovoo.com/fonts/trenda/35413A_4_0.woff') format('woff'), url('https://webassets.lovoo.com/fonts/trenda/35413A_4_0.ttf') format('truetype');
}
@font-face {
  font-family: 'lovoo-icons';
  font-style: normal;
  font-weight: 400;
  src: url('https://webassets.lovoo.com/fonts/lovoo-icons.ttf') format('truetype'), url('https://webassets.lovoo.com/fonts/lovoo-icons.woff') format('woff'), url('https://webassets.lovoo.com/fonts/lovoo-icons.svg') format('svg');
}

b {
  font-weight: 500;
}

p {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
}
h1 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
  font-size: 28px;
  font-weight: 600;
}
h2 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
  font-size: 20px;
  font-weight: 500;
}
h3 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}
h4 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
}
h5 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
}
h6 {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
}

body {
  font-family: 'Trenda', Arial, Helvetica, sans-serif !important;
}
