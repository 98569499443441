.blockcontent {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  top: 0;
}

.cookieplacer {
  width: 100vw;
  height: auto;
  overflow: hidden;
  position: absolute;
  bottom: 10%;
  left: 0;
}

.cookieconsent {
  position: relative;
  height: 100%;
  max-height: 70vh;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--lvo-white);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  color: var(--lvo-middle-grey);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.cookieconsent h1 {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.cookieconsent p {
  font-size: 1rem;
  font-weight: 200;
}

.buttoncontainer {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.secondarybuttoncontainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.secondarybuttoncontainer > div {
  cursor: pointer;
}

.cookie-customcontainer {
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 100%;
}

.consent-spacer {
  padding: 0 0.5rem;
}

.cookie-custom-element {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.035);
  border-radius: 10px;
  margin-bottom: 1rem;
}

.cookie-custom-elemt-content > h1 {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: var(--lvo-middle-grey);
  flex: 1;
}

.cookie-custom-elemt-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.cookie-custom-element > p {
  flex: 1;
  font-size: 1rem;
  font-weight: 200;
  padding: 0 1.5rem 0 0;
}

.cookie-custom-elemt-switch {
  width: auto;
  height: auto;
}

.scrollcookie {
  flex: 1;
  overflow: hidden auto;
  height: 100%;
}

.scrollcookie::-webkit-scrollbar {
  display: none;
}

.fixCookie {
  height: auto;
}

@media (min-width: 768px) {
  .cookieconsent {
    width: 500px;
    margin: 0 auto;
  }

  .scrollcookie::-webkit-scrollbar {
    display: initial;
  }
}
