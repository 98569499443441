@import './root.css';
@import './font.css';
@import './footer.css';
@import './albums.css';
@import './podcastplayer.css';
@import './scrollbar.css';
@import './audioplayer.css';
@import './cookieconsent.css';

body {
  margin: 0;
  padding: 0;
}

.header-image {
  background-image: url('../graphics/header.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.podcast-logo {
  width: 400px;
  max-width: 50%;
  padding: 0 0 0 3.25rem;
}

.podcast-logo-white {
  width: 400px;
  max-width: 75%;
  padding: 0 0 6rem 0;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}

.shape-content {
  padding: 8rem 1.5rem;
  height: 100%;
}

.external-podcast {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.external-podcast > svg {
  width: auto;
  height: 2rem;
  margin-right: 2rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.lvo-button {
  padding: 1rem 3rem;
  border-radius: 1000px;
  background-image: var(--lvo-grad-blue-blue);
  width: fit-content;
  margin: 50px auto;
  cursor: pointer;
}

@media (min-width: 576px) {
  .shape-content {
    padding: 9rem 9rem;
  }

  .header-image {
    align-items: center;
    justify-content: flex-start;
    height: 450px;
  }

  .external-podcast > svg {
    margin-bottom: none;
  }
}

.albums-patting-0 {
  padding-top: 0;
}

.albums-patting {
  padding-top: 9rem;
}

@media (min-width: 576px) {
  .albums-patting {
    padding-top: 8rem;
  }

  .lvo-button {
    padding: 1rem 4rem;
    border-radius: 1000px;
    width: fit-content;
    margin: 50px auto;
  }
}
