.podcast-player {
  margin: 3rem 0 0 0;
}

.episode-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.episode-image > div {
  min-width: 80%;
  padding-bottom: 80%;
  background-size: contain;
  border-radius: calc(var(--LVO-borderradius) + 0.75rem);
}

.podcast-queue {
  max-height: 150px;
  overflow: auto;
  padding: 1rem 1.5rem 1rem 1rem;
  border: solid 1px var(--lvo-white);
  border-radius: var(--LVO-borderradius);
  position: relative;
}

.podcast-queue::-webkit-scrollbar {
  display: none !important;
  -webkit-appearance: none;
  width: 0;
}

.episode-queue {
  margin: 0 0 2rem 0;
}

.podcast-queue-fader {
  position: relative;
  width: calc(100% - 2px);
  height: 2.5rem;
  margin: calc(-2.5rem - 1px) 0 0 1px;
  border-radius: var(--LVO-borderradius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: linear-gradient(0deg, var(--lvo-sky-blue) 20%, transparent 100%);
  pointer-events: none;
  display: none;
}

.queue-element {
  cursor: pointer;
}

.queue-element > h3 {
  line-height: 1.1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.episode-text > p {
  font-size: 0.9rem;
}

hr {
  margin: 0 0 0.5rem 0;
  padding: 0;
}

.expisode-head-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.3rem 0;
}

.expisode-head-container > h2 {
  padding: 0;
  margin: 0;  
}

.share-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

.share-button {
  background: var(--lvo-sky-blue);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
}

.share-button > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.share-container {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  background-color: var(--lvo-white);
  overflow: hidden;
  margin: 0 -1.25rem 0 0;
}

.share-container > button {
  margin-left: 0.5rem;
  margin-bottom: 1px;
}

.share-container > button:first-child {
  margin-left: 0.35rem;
}

.share-container > button:last-child {
  margin-right: calc(1.15rem + 0.6rem);
}

.share-container > button > svg {
  height: 1.5rem;
  width: auto;
}

@media (min-width: 576px) {
  .episode-image {
    flex: 0;
    padding: 0;
  }

  .episode-image > div {
    min-width: 220px;
    height: 220px;
    padding-bottom: unset;
  }

  .episode-queue {
    flex: 1 1;
    padding-left: 2.5rem !important;
    margin: 0;
  }

  .podcast-queue {
    max-height: 179px;
  }
}
