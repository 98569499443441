/*LOVOO COLORS*/
:root {
  /*primary colors*/
  --lvo-blue-strong: #3E5CCC;
  --lvo-blue: #257af4;
  --lvo-blue-light: #779ff7;
  --lvo-watermelon-red: #f73e59;
  --lvo-sunny-yellow: #ffbb33;
  /*secondary colors*/
  --lvo-purple-red: #ac145a;
  --lvo-apple-green: #1ece50;
  --lvo-purple: #bd10e0;
  /*tertiary colors (for gradients only)*/
  --lvo-sky-blue: #55b5ff;
  --lvo-light-purple: #c471ed;
  /*neutral colors*/
  --lvo-white: #ffffff;
  --lvo-soft-grey: #f5f5f5;
  --lvo-light-grey: #e6e6e6;
  --lvo-grey: #bebebe;
  --lvo-middle-grey: #777777;
  --lvo-soft-black: #444444;
  --lvo-black: #000000;
  /*gradient colors*/
  --lvo-grad-blue-blue: linear-gradient(135deg, var(--lvo-blue-strong), var(--lvo-blue-light));
  --lvo-grad-blue-purple: linear-gradient(135deg, var(--lvo-sky-blue), var(--lvo-light-purple));
  --lvo-grad-blue-purple-red: linear-gradient(135deg, var(--lvo-sky-blue), var(--lvo-light-purple), var(--lvo-watermelon-red));
  --lvo-grad-red-yellow: linear-gradient(135deg, var(--lvo-watermelon-red), var(--lvo-sunny-yellow));
}

/*LOVOO EFFECTS*/
:root {
  --LVO-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  --LVO-borderradius: 0.7rem;
}

#root {
  background: var(--lvo-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--lvo-white);
}

.root-container {
  width: 100%;
  max-width: 1000px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: var(--lvo-white);
  box-shadow: 0px 0px 150px 50px rgba(0, 0, 0, 0.15);
}
