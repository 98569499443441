.albums-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 3rem 0 1rem 0;
  margin-bottom: 2rem;
  margin-top: 1rem;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 1.5rem;
  cursor: grab;
  user-select: none;
}

.anchor-ref{
  padding: 45% 52% 89% 45%
}

.albums-container::-webkit-scrollbar {
  display: none;
  background-color: transparent;
}

.albums-container::after {
  content: '';
  padding-right: 11rem;
}

.albums-container > .album {
  flex: 0 0 auto;

  margin: 0 1.5rem 0 1.5rem;
  scroll-snap-align: start;
}

.albums-container > .album > .album-content {
  width: 200px;
  height: 200px;
  background-image: var(--lvo-grad-red-yellow);
  border-radius: var(--LVO-borderradius);
  overflow: hidden;
  margin: 0 0 1rem 0;
  box-shadow: var(--LVO-shadow);
  background-size: cover;
}

.albums-container > .album:last-child {
  margin: 0 1.5rem 0 1.5rem;
}

.album > h1 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
}

.album > h2 {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 0.5rem;
}

.album-content {
  transition: all ease-in-out 0.25s;
}

@media (min-width: 576px) {
  .albums-container {
    margin-top: 3rem;
    scroll-padding-left: 9rem;
  }
  .albums-container > .album {
    margin: 0 -6.5rem 0 9rem;
  }

  .albums-container > .album:last-child {
    margin: 0 9rem 0 9rem;
  }

  .albums-container::after {
    content: '';
    padding-right: 32rem; /* smallest size that is cross browser */
  }
}
