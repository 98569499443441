.simplebar-scrollbar:before {
  background: var(--lvo-white);
  opacity: 1 !important;
  margin-bottom: 2.5px;
}

.simplebar-track.simplebar-vertical {
  background: var(--lvo-sky-blue);
  height: 90% !important;
  top: 5%;
  border-radius: var(--LVO-borderradius);
  border: solid 1px var(--lvo-white);
  margin-right: 0.4rem;
}
