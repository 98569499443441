.footer {
  color: var(--lvo-soft-black);
  padding: 0 1.5rem;
  padding-bottom: 2rem;
}

.footer-section {
  display: flex;
  flex-direction: row;
  padding: 0 0 24px 0;
}

.footer-section-icon {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 6px 0 0;
}

.footer-section-icon > svg {
  width: 1rem;
}

.footer-section-content {
  display: flex;
  flex-direction: column;
}

.footer-section-headline {
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}

.footer-section-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--lvo-soft-black);
  text-decoration: none;
  white-space: nowrap;
}

.section-padding {
  padding-left: calc(1rem + 6px);
}

.socialmedia-section {
  padding-top: 10px;
}

.socialmedia-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 12px;
  color: var(--lvo-soft-black);
}

.socialmedia-icon {
  width: 2.5rem;
  height: 2.5rem;
}

.socialmedia-closure {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

@media (min-width: 576px) {
  .footer {
    color: var(--lvo-black);
    padding: 0 9rem;
    padding-bottom: 2rem;
  }
  .socialmedia-icon {
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 768px) {
  .socialmedia-icons {
    padding-bottom: 12px;
    justify-content: flex-start;
  }

  .socialmedia-closure {
    text-align: left;
  }

  .socialmedia-icon {
    width: 25px;
    height: 25px;
    margin: 0 0.25rem 0 0;
  }

  .socialmedia-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
