.rhap_container {
  background: transparent;
  box-shadow: none;
  margin: 1.5rem 0 -1rem 0;
  padding: 0;
}

.rhap_container:focus {
  outline-width: 0;
  box-shadow: none;
  outline-color: transparent;
  outline-style: none;
}

.rhap_container img {
  vertical-align: unset;
}

.rhap_play-pause-button {
  width: 5rem;
  height: 5rem;
}

.rhap_play-pause-button > img {
  width: 100%;
  height: 100%;
}

.rhap_skip-button {
  width: 2rem;
  height: 2rem;
  margin: 0 1rem;
}

.rhap_skip-button > img {
  height: 2rem;
}

.rhap_volume-controls {
  display: none;
}

.rhap_additional-controls {
  display: none;
}

.rhap_controls-section {
  display: flex;
  justify-content: center;
}

.rhap_main-controls {
  margin-top: 1rem;
}

.rhap_time {
  color: var(--lvo-white);
}

.rhap_progress-bar-show-download {
  background-color: rgba(255, 255, 255, 0.25);
}

.rhap_progress-filled {
  background-color: var(--lvo-white);
}

.rhap_download-progress {
  background-color: rgba(255, 255, 255, 0.25);
}

.rhap_progress-indicator {
  background: var(--lvo-white);
}

.rhap_header {
  text-align: center;
}
